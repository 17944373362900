@import "~ng-zorro-antd/slider/style/index.min.css";

html,
body {
  height: 100%;
  background-color: black;
  color: white;
}
body {
  margin: 0;
}

/* width of the entire scrollbar */
html::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
/* color of the tracking area */
html::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
}
html::-webkit-scrollbar-thumb {
  background: #434343;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%),
    inset -2px -2px 2px rgb(0 0 0 / 25%);
}
.mat-card {
  background: #1f1f1f;
  color: rgb(230, 230, 230);
}

.mat-tab-label {
  font-size: calc(1.1rem + 1.5vmin);
}

a {
  text-decoration: none;
}

h1 {
  margin: 0 !important;
  font-size: calc(1.2rem + 3vmin) !important;
  font-weight: bold !important;
  font-family: "Yantramanav", sans-serif !important;
}

h2 {
  margin: 0;
  font-size: calc(0.8rem + 2vmin) !important;
  font-weight: bold !important;
  font-family: "Yantramanav", sans-serif !important;
}
h3 {
  margin: 10px !important;
  font-size: calc(1.1rem + 1.5vmin) !important;
  font-weight: bold !important;
  font-family: "Yantramanav", sans-serif !important;
}
h4 {
  margin: 0 !important;
  font-size: calc(0.7rem + 1vmin) !important;
  font-weight: normal;
  font-family: "Yantramanav", sans-serif !important;
}
p {
  margin: 0;
  font-size: calc(0.8rem + 0.5vmin);
  font-weight: normal;
  font-family: "Raleway", "Helvetica Neue", sans-serif;
}
span {
  margin: 0;
  font-size: calc(0.7rem + 0.5vmin);
  font-family: "Raleway", "Helvetica Neue", sans-serif;
}

.mat-tab-label {
  margin: 0;
  font-size: calc(0.8rem + 2vmin) !important;
  font-weight: bold !important;
  font-family: "Yantramanav", sans-serif !important;
}

.mat-card-title {
  margin: 0;
  font-size: calc(0.8rem + 0.5vmin);
  font-weight: normal;
  font-family: "Yantramanav", "Helvetica Neue", sans-serif;
}

.mat-card-subtitle {
  margin: 0;
  font-size: calc(0.7rem + 0.5vmin);
  font-family: "Raleway", "Helvetica Neue", sans-serif;
}

.mat-checkbox-background {
  background-color: rgb(204, 204, 204);
}

.text-centered {
  text-align: center;
}

#logo {
  position: absolute;
  top: 0;
  left: 0;
  padding: calc(1rem + 2vmin);
}

#logo-text {
  font-size: calc(2rem + 3vmin) !important;
}

.white {
  color: white;
}

.bigger {
  padding-top: 12px;
  line-height: calc(0.5rem + 3vmin);
  font-size: calc(1rem + 0.5vmin);
}

/* gt-xs */
@media screen and (min-width: 600px) {
  .mat-tab-label,
  .mat-tab-label-active {
    min-width: 128px !important;
  }
}
/* xs */
@media screen and (max-width: 599px) {
  .mat-tab-label,
  .mat-tab-label-active {
    padding: 0px 12px !important;
    width: 33% !important;
  }
}

.mat-tab-label.mat-tab-label-active {
  opacity: 1;
}

.mat-tab-body-wrapper {
  height: 100%;
}
